import React, { useState, useEffect } from "react";

import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function CrearMaquina() {
  const [dataTypes, setDataTypes] = React.useState([]);
  const [dataZones, setDataZones] = React.useState([]);
  const [tipo, setTipo] = React.useState({ target: { value: "" } });
  const [zona, setZona] = React.useState({ target: { value: "" } });
  const [nombre, setNombre] = React.useState("");
  const [codigo, setCodigo] = React.useState("");
  const [errors, setErrors] = React.useState({
    nombre: "",
    tipo: "",
    zona: "",
  });

  const fetchData = async () => {
    // const resultTypes = await axios(
    //   "http://localhost:5000/api/maquinas/alltypes"
    // );
    const resultTypes = await axios(
      "https://epstool.com/api/maquinas/alltypes"
    );
    // const resultZones = await axios(
    //   "http://localhost:5000/api/maquinas/allzones"
    // );
    const resultZones = await axios(
      "https://epstool.com/api/maquinas/allzones"
    );
    setDataTypes(resultTypes.data);
    setDataZones(resultZones.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const verificar = () => {
    let errorMessage = { id: 0, nombre: "", tipo: "", zona: "" };
    let errors = 0;

    if (!nombre) {
      errorMessage.nombre = "Debe escribir el nombre del equipo";
      errors = errors + 1;
    }
    if (!tipo.target.value) {
      errorMessage.tipo = "Debe seleccionar el tipo del equipo";
      errors = errors + 1;
    }
    if (!zona.target.value) {
      errorMessage.zona = "Debe seleccionar la ubicacion equipo";
      errors = errors + 1;
    }
    setErrors(errorMessage);
    return errors;
  };

  const handleMaquinaSubmit = async (event) => {
    event.preventDefault();
    let flag = verificar();
    if (flag === 0) {
      const maquinaToSave = {
        codMaquina: nombre,
        codEspecial: codigo,
        ubicacion: zona.target.value,
        tipo: tipo.target.value,
      };
      await axios
        .post("https://epstool.com/api/maquinas/nuevamaquina", maquinaToSave)
        // .post("http://localhost:5000/api/maquinas/nuevamaquina", maquinaToSave)
        .then((res) => {
          setNombre("");
          setCodigo("");
          setZona({ target: { value: "" } });
          setTipo({ target: { value: "" } });
          setErrors({ nombre: "", tipo: "", zona: "" });
          // showNotification();
        })
        .catch((error) => {
          setErrors(console.log(error));
        });
    }
  };

  const classes = useStyles();

  let selecType;
  selecType = (
    <GridItem xs={12} sm={6}>
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Tipo de maquina*
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={tipo.target.value}
          onChange={(e) => setTipo(e)}
        >
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem,
            }}
          >
            Tipo de Maquina
          </MenuItem>
          {dataTypes.map((tipos) => (
            <MenuItem
              key={tipos.consecutivo}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={tipos._id}
            >
              {tipos.consecutivo} - {tipos.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Danger>
        {errors.tipo === undefined ? null : (
          <div className="invalid-feedback">{errors.tipo}</div>
        )}
      </Danger>
    </GridItem>
  );
  let selectZone;
  selectZone = (
    <GridItem xs={12} sm={6}>
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Zona*
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={zona.target.value}
          onChange={(e) => setZona(e)}
        >
          <MenuItem
            disabled
            classes={{
              root: classes.selectMenuItem,
            }}
          >
            Ubicación
          </MenuItem>
          {dataZones.map((zona) => (
            <MenuItem
              key={zona._id}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={zona._id}
            >
              {zona.consecutivo} - {zona.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Danger>
        {errors.zona === undefined ? null : (
          <div className="invalid-feedback">{errors.zona}</div>
        )}
      </Danger>
    </GridItem>
  );

  return (
    <GridContainer>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4 className={classes.cardTitle}>Crear Maquina</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Nombre*
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  id="nombre"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => setNombre(e.target.value),
                    type: "text",
                    value: nombre,
                  }}
                />
                <Danger>
                  {errors.nombre === undefined ? null : (
                    <div className="invalid-feedback">{errors.nombre}</div>
                  )}
                </Danger>
              </GridItem>
              {selecType}
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Código
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  id="codigo"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => setCodigo(e.target.value),
                    type: "text",
                    value: codigo,
                  }}
                />
              </GridItem>
              {selectZone}
            </GridContainer>
          </form>
          <br></br>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Button fullWidth color="success" onClick={handleMaquinaSubmit}>
                Crear equipo
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
