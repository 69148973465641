import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Place from "@material-ui/icons/Place";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";

import Forward from "@material-ui/icons/Forward";

const useStyles = makeStyles(styles);

export default function ListaMaquinas(props) {
  const classes = useStyles();
  const [equipos, setEquipos] = React.useState([]);

  // CARGA DE LA RONDA
  const fetchData = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // `http://localhost:5000/api/maquinas/lista`
      `https://epstool.com/api/maquinas/lista`
    );
    console.log(result.data);
    setEquipos(result.data);
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  let tbEquipos;
  Object.keys(equipos).length >= 1
    ? (tbEquipos = equipos.map((equipo) => {
        return {
          id: equipo._id,
          nombre: equipo.codMaquina,
          zona: equipo.ubicacion.nombre,
          actions: (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  props.history.push(`maquina/${equipo.uuid}`);
                }}
                color="success"
                className="edit"
              >
                <Forward />
              </Button>{" "}
            </div>
          ),
        };
      }))
    : (tbEquipos = []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Place />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Lista de equipos</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: "Nombre",
                  accessor: "nombre",
                },
                {
                  Header: "Zona",
                  accessor: "zona",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                },
              ]}
              data={tbEquipos}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
