import CrearMaquina from "views/Maquina/crearMaquina.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import RondasConfig from "views/Rondas/rondasConfig.js";
import Ronda from "views/Rondas/ronda.js";
import RondaDetails from "views/Rondas/rondaDetails.js";
import Maquina from "views/Maquina/maquina.js";
import ListaMaquinas from "views/Maquina/listaMaquinas.js";
import ExternalEquipo from "views/QR/externalEquipo";
import QrEquipo from "views/QR/qrEquipo.js";
import QrScanner from "views/QR/qrScanner.js";
import TipoMaquina from "views/Maquina/TipoMaquina.js";
import InformeForm from "views/Informes/informeForm.js";
import LoginPage from "views/Pages/LoginPage.js";
import ListaRondas from "views/Rondas/listaRondas.js";
import Informe from "views/Informes/informe.js";
import CambiarPass from "views/User/cambiarPass.js";
import BMS from "views/Apps/Apps";
import Apps from "@material-ui/icons/Apps";
import AnalisisConfig from "views/Analisis/analisisConfig.js";
import GraficosComparativos from "views/Analisis/graficosComparativos.js";
import ListAltIcon from "@material-ui/icons/ListAlt";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import ApartmentIcon from "@material-ui/icons/Apartment";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Graficos from "views/Maquina/graficos";
import TimelineIcon from "@material-ui/icons/Timeline";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/listaequipos",
    name: "Equipos",
    rtlName: "لوحة القيادة",
    icon: VideoLabelIcon,
    component: ListaMaquinas,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/qrscanner",
    name: "QR Scanner",
    rtlName: "لوحة القيادة",
    icon: VideoLabelIcon,
    component: QrScanner,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/cambiar_pass",
    name: "Cambiar contraseña",
    rtlName: "لوحة القيادة",
    icon: VideoLabelIcon,
    component: CambiarPass,
    layout: "/admin",
  },
  {
    path: "/listarondas",
    name: "Rondas",
    rtlName: "لوحة القيادة",
    icon: ApartmentIcon,
    component: ListaRondas,
    layout: "/admin",
  },
  {
    path: "/graficosAvanzados/",
    name: "Análisis",
    icon: TimelineIcon,
    mini: "AN",
    component: GraficosComparativos,
    layout: "/admin",
  },
  {
    path: "/analisis",
    name: "Reportes",
    mini: "AN",
    icon: ListAltIcon,
    component: AnalisisConfig,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/qrcode/equipo/:_id",
    name: "Qr Equipo",
    mini: "RD",
    component: QrEquipo,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/security/:_id/:nfctag",
    name: "Equipo externo",
    mini: "RD",
    component: ExternalEquipo,
    layout: "/admin",
  },
  {
    path: "/rondaconfig",
    name: "Crear Ronda",
    mini: "CR",
    icon: QueryBuilderIcon,
    component: RondasConfig,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/informes/:_id",
    name: "Informe",
    mini: "IN",
    component: Informe,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/ronda/:_id",
    name: "Ronda",
    mini: "RD",
    component: Ronda,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/rondadetalles/:_id",
    name: "RondaDetails",
    mini: "RD",
    component: RondaDetails,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/maquina/:_id",
    name: "Equipo",
    mini: "EQ",
    component: Maquina,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/graficos/:_id",
    name: "Graficos",
    mini: "GR",
    component: Graficos,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/informe/:idmaq/:idronda/:idform/:idnfc",
    name: "Informe",
    mini: "IN",
    component: InformeForm,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/nuevotipo",
    name: "Tipo Maquina",
    mini: "TM",
    component: TipoMaquina,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/crearmaquina",
    name: "Crear Maquina",
    mini: "CM",
    component: CrearMaquina,
    layout: "/admin",
  },
  {
    path: "/apps",
    name: "Aplicaciones DMS",
    rtlName: "لوحة القيادة",
    icon: Apps,
    component: BMS,
    layout: "/admin",
  },
  {
    collapse: true,
    invisible: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
