import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Place from "@material-ui/icons/Place";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import { saveAs } from "file-saver";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

import Dvr from "@material-ui/icons/Dvr";
import Assessment from "@material-ui/icons/Assessment";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function RondaDetails(props) {
  const classes = useStyles();
  const idRonda = props.match.params._id;
  const [ronda, setRonda] = React.useState();
  const [equipos, setEquipos] = React.useState({});
  const [equiposDetallados, setEquiposDetallados] = React.useState({});
  const [flagData, setFlagData] = React.useState();
  const [savingModal, setSavingModal] = React.useState(false);

  // CARGA DE LA RONDA
  const fetchData = async () => {
    // console.log(idRonda);

    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // `http://localhost:5000/api/maquinas/rondadetails/${idRonda}`
      `https://epstool.com/api/maquinas/rondadetails/${idRonda}`
    );
    setRonda(result.data.ronda);
    console.log(result.data.ronda);

    // console.log(result.data.ronda.informesDetallados);

    if (result.data.ronda.informes.length >= 1) {
      setEquipos(result.data.ronda.informes);
      setFlagData(0);
    } else {
      setEquiposDetallados(result.data.ronda.informesDetallados);
      setFlagData(1);
    }
    // console.log(result.data.ronda);
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const generarReporte = async () => {
    setSavingModal(true);
    const fecha = new Date(ronda.fechaInicio * 1000);
    const horaInicial = new Date(ronda.informes[0].fecha * 1000);
    const horaFinal = new Date(
      ronda.informes[ronda.informes.length - 1].fecha * 1000
    );
    let omitidos = [];
    let valores = [];
    ronda.hallazgos.forEach((element) => {
      if (element.comentario.includes("omitido")) {
        let valor = {
          maquina: element.maquina,
          comentario: element.comentario.substring(27),
        };
        omitidos.push(valor);
      } else {
        if (element.valores.length >= 1) {
          let valor = {
            maquina: element.maquina,
            comentario: element.comentario,
            valores: element.valores,
          };
          valores.push(valor);
        }
      }
    });

    let omitidosPdf = "";
    let hallazgosPdf = "";

    for (let index = 0; index < valores.length; index++) {
      const element = valores[index];
      let valoresPdf = "";

      for (let i = 0; i < valores[index].valores.length; i++) {
        const element = valores[index].valores[i];
        let string;
        string = `
          <ul>
              <li>${element.nombre}: ${element.valor}
          <ul>
              <li>${element.comentario}</li>
          </ul>
              </li>
          </ul>
              
          `;
        valoresPdf += string;
      }

      let string;
      string = `<li>${element.maquina} ${
        element.comentario ? `Comentario general: ${element.comentario}` : ""
      }
      <ul>
        <li>
            Detalles:
            ${valoresPdf}
        </li>
      </ul>
      </li>
      `;
      hallazgosPdf += string;
    }

    for (let index = 0; index < omitidos.length; index++) {
      const element = omitidos[index];
      let string;
      string = `<li>${element.maquina} Justificación: ${element.comentario}</li>`;
      omitidosPdf += string;
    }

    let informePdf = "";

    for (let index = 0; index < ronda.informes.length; index++) {
      let informacionPdf = "";

      const element = ronda.informes[index];
      for (let i = 0; i < ronda.informes[index].informacion.length; i++) {
        const element = ronda.informes[index].informacion[i];
        let string;
        string = `<li>${element.nombre}: ${element.valor}</li>`;
        informacionPdf += string;
      }
      let string;
      string = `<li>Equipo ${element.maquina.codMaquina}
                <ul>
                    ${informacionPdf}
                    <li>Equipo revisado por: ${element.user.name}</li>
                </ul>
            </li>`;
      informePdf += string;
    }

    const rondaInfo = {
      nombre: ronda.nombre,
      codigo: ronda.codigo,
      fecha: `${fecha.getDate()}/${
        fecha.getMonth() + 1
      }/${fecha.getFullYear()}`,
      horaInicio: `${horaInicial.getDate()}/${
        horaInicial.getMonth() + 1
      }/${horaInicial.getFullYear()} ${horaInicial.getHours()}:${horaInicial.getMinutes()}`,
      horaTerminar: `${horaFinal.getDate()}/${
        horaFinal.getMonth() + 1
      }/${horaFinal.getFullYear()} ${horaFinal.getHours()}:${horaFinal.getMinutes()}`,
      printOmitidos: omitidosPdf,
      printHallazgos: hallazgosPdf,
      printInformes: informePdf,
    };

    await axios
      // .post("http://localhost:5000/api/maquinas/exportarRonda", rondaInfo)
      .post("https://epstool.com/api/maquinas/exportarRonda", rondaInfo)
      .then(() =>
        axios
          // .get("http://localhost:5000/api/maquinas/fecth-pdf", {
          .get("https://epstool.com/api/maquinas/fecth-pdf", {
            responseType: "blob",
          })
          .catch((err) => console.log(err))
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });

        saveAs(pdfBlob, `Reporte.pdf`);
        setSavingModal(false);
      })
      .catch((err) => console.log(err));
  };

  const generarReporteHallazgos = async () => {
    setSavingModal(true);
    const fecha = new Date(ronda.fechaInicio * 1000);
    const horaInicial = new Date(ronda.informes[0].fecha * 1000);
    const horaFinal = new Date(
      ronda.informes[ronda.informes.length - 1].fecha * 1000
    );
    let omitidos = [];
    let valores = [];
    ronda.hallazgos.forEach((element) => {
      if (element.comentario.includes("omitido")) {
        let valor = {
          maquina: element.maquina,
          comentario: element.comentario.substring(27),
        };
        omitidos.push(valor);
      } else {
        if (element.valores.length >= 1) {
          let valor = {
            maquina: element.maquina,
            comentario: element.comentario,
            valores: element.valores,
          };
          valores.push(valor);
        }
      }
    });

    let omitidosPdf = "";
    let hallazgosPdf = "";

    for (let index = 0; index < valores.length; index++) {
      const element = valores[index];
      let valoresPdf = "";

      for (let i = 0; i < valores[index].valores.length; i++) {
        const element = valores[index].valores[i];
        let string;
        string = `
          <ul>
              <li>${element.nombre}: ${element.valor}
          <ul>
              <li>${element.comentario}</li>
          </ul>
              </li>
          </ul>
              
          `;
        valoresPdf += string;
      }

      let string;
      string = `<li>${element.maquina} ${
        element.comentario ? `Comentario general: ${element.comentario}` : ""
      }
      <ul>
        <li>
            Detalles:
            ${valoresPdf}
        </li>
      </ul>
      </li>
      `;
      hallazgosPdf += string;
    }

    for (let index = 0; index < omitidos.length; index++) {
      const element = omitidos[index];
      let string;
      string = `<li>${element.maquina} Justificación: ${element.comentario}</li>`;
      omitidosPdf += string;
    }

    const rondaInfo = {
      nombre: ronda.nombre,
      codigo: ronda.codigo,
      fecha: `${fecha.getDate()}/${
        fecha.getMonth() + 1
      }/${fecha.getFullYear()}`,
      horaInicio: `${horaInicial.getDate()}/${
        horaInicial.getMonth() + 1
      }/${horaInicial.getFullYear()} ${horaInicial.getHours()}:${horaInicial.getMinutes()}`,
      horaTerminar: `${horaFinal.getDate()}/${
        horaFinal.getMonth() + 1
      }/${horaFinal.getFullYear()} ${horaFinal.getHours()}:${horaFinal.getMinutes()}`,
      printOmitidos: omitidosPdf,
      printHallazgos: hallazgosPdf,
    };

    await axios
      // .post("http://localhost:5000/api/maquinas/exportarHallazgos", rondaInfo)
      .post("https://epstool.com/api/maquinas/exportarHallazgos", rondaInfo)
      .then(() =>
        axios
          // .get("http://localhost:5000/api/maquinas/fecth-pdf-hallazgos", {
          .get("https://epstool.com/api/maquinas/fecth-pdf-hallazgos", {
            responseType: "blob",
          })
          .catch((err) => console.log(err))
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });

        saveAs(pdfBlob, `Hallazgos.pdf`);
        setSavingModal(false);
      })
      .catch((err) => console.log(err));
  };

  const generarDetallesReporteHallazgos = async () => {
    setSavingModal(true);
    const fecha = new Date(ronda.horaInicio * 1000);
    const horaInicial = new Date(ronda.horaInicio * 1000);
    const horaFinal = new Date(ronda.horaFinal * 1000);
    let omitidos = [];
    let valores = [];
    equiposDetallados.forEach((element) => {
      if (element.hallazgos[0].omitir === true) {
        let valor = {
          maquina: element.maquina,
          comentario: element.hallazgos[0].comentario.substring(27),
        };
        omitidos.push(valor);
      } else {
        if (element.hallazgos[0].valores.length > 0) {
          let valor = {
            maquina: element.maquina,
            comentario: element.hallazgos[0].comentario,
            valores: element.hallazgos[0].valores,
            variables: element.tipoMaq.variables,
          };
          valores.push(valor);
        }
      }
    });
    let omitidosPdf = "";
    let hallazgosPdf = "";

    for (let index = 0; index < valores.length; index++) {
      const element = valores[index];
      let valoresPdf = "";

      for (let i = 0; i < valores[index].valores.length; i++) {
        const element = valores[index].valores[i];
        let unidadMedida = "";
        let minimo = "";
        let maximo = "";

        if (valores[0].variables) {
          for (let j = 0; j < valores[index].variables.length; j++) {
            const tipoMaq = valores[index].variables[j];

            if (tipoMaq.nombre === element.nombre) {
              unidadMedida = tipoMaq.unidadMedida;
              if (tipoMaq.minimo) {
                minimo = `- Min: ${tipoMaq.minimo}`;
              }
              if (tipoMaq.maximo) {
                maximo = `- Max: ${tipoMaq.maximo}`;
              }
            }
          }
        }
        let string;
        string = `
          <ul>
              <li>${element.nombre}: ${element.valor} ${unidadMedida} ${minimo} ${maximo}
          <ul>
              <li>${element.comentario}</li>
          </ul>
              </li>
          </ul>
              
          `;
        valoresPdf += string;
      }

      let string;
      string = `<li>${element.maquina} ${
        element.comentario ? `Comentario general: ${element.comentario}` : ""
      }
      <ul>
        <li>
            Detalles:
            ${valoresPdf}
        </li>
      </ul>
      </li>
      `;
      hallazgosPdf += string;
    }

    for (let index = 0; index < omitidos.length; index++) {
      const element = omitidos[index];
      let string;
      string = `<li>${element.maquina} Justificación: ${element.comentario}</li>`;
      omitidosPdf += string;
    }

    const rondaInfo = {
      nombre: ronda.nombre,
      codigo: ronda.codigo,
      fecha: `${fecha.getDate()}/${
        fecha.getMonth() + 1
      }/${fecha.getFullYear()}`,
      horaInicio: `${horaInicial.getDate()}/${
        horaInicial.getMonth() + 1
      }/${horaInicial.getFullYear()} ${horaInicial.getHours()}:${horaInicial.getMinutes()}`,
      horaTerminar: `${horaFinal.getDate()}/${
        horaFinal.getMonth() + 1
      }/${horaFinal.getFullYear()} ${horaFinal.getHours()}:${horaFinal.getMinutes()}`,
      printOmitidos: omitidosPdf,
      printHallazgos: hallazgosPdf,
    };

    await axios
      // .post("http://localhost:5000/api/maquinas/exportarHallazgos", rondaInfo)
      .post("https://epstool.com/api/maquinas/exportarHallazgos", rondaInfo)
      .then(() =>
        axios
          // .get("http://localhost:5000/api/maquinas/fecth-pdf-hallazgos", {
          .get("https://epstool.com/api/maquinas/fecth-pdf-hallazgos", {
            responseType: "blob",
          })
          .catch((err) => console.log(err))
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });

        saveAs(pdfBlob, `Hallazgos.pdf`);
        setSavingModal(false);
      })
      .catch((err) => console.log(err));
  };

  const generarDetallesReporte = async () => {
    setSavingModal(true);
    const fecha = new Date(ronda.horaInicio * 1000);
    const horaInicial = new Date(ronda.horaInicio * 1000);
    const horaFinal = new Date(ronda.horaFinal * 1000);
    let omitidos = [];
    let valores = [];
    equiposDetallados.forEach((element) => {
      if (element.hallazgos[0].omitir === true) {
        let valor = {
          maquina: element.maquina,
          comentario: element.hallazgos[0].comentario.substring(27),
        };
        omitidos.push(valor);
      } else {
        if (element.hallazgos[0].valores.length > 0) {
          let valor = {
            maquina: element.maquina,
            comentario: element.hallazgos[0].comentario,
            valores: element.hallazgos[0].valores,
            variables: element.tipoMaq.variables,
          };
          valores.push(valor);
        }
      }
    });
    let omitidosPdf = "";
    let hallazgosPdf = "";

    for (let index = 0; index < valores.length; index++) {
      const element = valores[index];
      let valoresPdf = "";

      for (let i = 0; i < valores[index].valores.length; i++) {
        const element = valores[index].valores[i];
        let unidadMedida = "";
        let minimo = "";
        let maximo = "";

        if (valores[0].variables) {
          for (let j = 0; j < valores[index].variables.length; j++) {
            const tipoMaq = valores[index].variables[j];

            if (tipoMaq.nombre === element.nombre) {
              unidadMedida = tipoMaq.unidadMedida;
              if (tipoMaq.minimo) {
                minimo = `- Min: ${tipoMaq.minimo}`;
              }
              if (tipoMaq.maximo) {
                maximo = `- Max: ${tipoMaq.maximo}`;
              }
            }
          }
        }
        let string;
        string = `
          <ul>
              <li>${element.nombre}: ${element.valor} ${unidadMedida} ${minimo} ${maximo}
          <ul>
              <li>${element.comentario}</li>
          </ul>
              </li>
          </ul>
              
          `;
        valoresPdf += string;
      }

      let string;
      string = `<li>${element.maquina} ${
        element.comentario ? `Comentario general: ${element.comentario}` : ""
      }
      <ul>
        <li>
            Detalles:
            ${valoresPdf}
        </li>
      </ul>
      </li>
      `;
      hallazgosPdf += string;
    }

    for (let index = 0; index < omitidos.length; index++) {
      const element = omitidos[index];
      let string;
      string = `<li>${element.maquina} Justificación: ${element.comentario}</li>`;
      omitidosPdf += string;
    }

    let informePdf = "";

    for (let index = 0; index < equiposDetallados.length; index++) {
      let informacionPdf = "";

      const element = equiposDetallados[index];
      if (element.informe) {
        for (
          let i = 0;
          i < equiposDetallados[index].informe.informacion.length;
          i++
        ) {
          const element = equiposDetallados[index].informe.informacion[i];
          const unidadMedida = "";
          if (equiposDetallados[index].tipoMaq.variables[i].unidadMedida) {
            unidadMedida =
              equiposDetallados[index].tipoMaq.variables[i].unidadMedida;
          }
          let string;
          string = `<li>${element.nombre}: ${element.valor} ${unidadMedida}</li>`;
          informacionPdf += string;
        }
        let string;
        string = `<li>Equipo ${element.maquina}
                  <ul>
                      ${informacionPdf}
                      <li>Equipo revisado por: ${element.user}</li>
                  </ul>
              </li>`;
        informePdf += string;
      }
    }

    const rondaInfo = {
      nombre: ronda.nombre,
      codigo: ronda.codigo,
      fecha: `${fecha.getDate()}/${
        fecha.getMonth() + 1
      }/${fecha.getFullYear()}`,
      horaInicio: `${horaInicial.getDate()}/${
        horaInicial.getMonth() + 1
      }/${horaInicial.getFullYear()} ${horaInicial.getHours()}:${horaInicial.getMinutes()}`,
      horaTerminar: `${horaFinal.getDate()}/${
        horaFinal.getMonth() + 1
      }/${horaFinal.getFullYear()} ${horaFinal.getHours()}:${horaFinal.getMinutes()}`,
      printOmitidos: omitidosPdf,
      printHallazgos: hallazgosPdf,
      printInformes: informePdf,
    };

    await axios
      // .post("http://localhost:5000/api/maquinas/exportarRonda", rondaInfo)
      .post("https://epstool.com/api/maquinas/exportarRonda", rondaInfo)
      .then(() =>
        axios
          // .get("http://localhost:5000/api/maquinas/fecth-pdf", {
          .get("https://epstool.com/api/maquinas/fecth-pdf", {
            responseType: "blob",
          })
          .catch((err) => console.log(err))
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });

        saveAs(pdfBlob, `Reporte.pdf`);
        setSavingModal(false);
      })
      .catch((err) => console.log(err));
  };

  let tbEquipos;
  if (flagData === 1) {
    Object.keys(equiposDetallados).length >= 1
      ? (tbEquipos = equiposDetallados.map((equipo) => {
          // console.log(equipo.estadoMultiple.includes(1105));

          return {
            nombre: equipo.maquina,
            // zona: equipo.ubicacion.nombre,
            zona: equipo.ubicacion,
            estado: equipo.estado === 0 ? "Pendiente" : "Completado",
            actions: (
              <div className="actions-right">
                {equipo.estado === 0 ? (
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      props.history.push(
                        `../informe/${equipo.uuid}/${idRonda}/${equipo.tipoMaq}`
                      );
                    }}
                    color="warning"
                    className="edit"
                  >
                    <Assessment />
                  </Button>
                ) : (
                  <Button
                    justIcon
                    round
                    simple
                    color="success"
                    className="edit"
                  >
                    <Assessment />
                  </Button>
                )}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    props.history.push(`../maquina/${equipo.uuid}`);
                  }}
                  color="warning"
                  className="edit"
                >
                  <Dvr />
                </Button>{" "}
              </div>
            ),
          };
        }))
      : (tbEquipos = []);
  } else {
    Object.keys(equipos).length >= 1
      ? (tbEquipos = equipos.map((equipo) => {
          // console.log(equipo.estadoMultiple.includes(1105));

          return {
            nombre: equipo.maquina.codMaquina,
            // zona: equipo.ubicacion.nombre,
            zona: equipo.maquina.ubicacion.nombre,

            actions: (
              <div className="actions-right">
                {equipo.estado === 0 ? (
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      props.history.push(
                        `../informe/${equipo.uuid}/${idRonda}/${equipo.tipoMaq}`
                      );
                    }}
                    color="warning"
                    className="edit"
                  >
                    <Assessment />
                  </Button>
                ) : (
                  <Button
                    justIcon
                    round
                    simple
                    color="success"
                    className="edit"
                  >
                    <Assessment />
                  </Button>
                )}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    props.history.push(`../maquina/${equipo.maquina.uuid}`);
                  }}
                  color="success"
                  className="edit"
                >
                  <Dvr />
                </Button>{" "}
              </div>
            ),
          };
        }))
      : (tbEquipos = []);
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <QueryBuilderIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Detalles
              {ronda ? ` ${ronda.configuracion.nombre} ${ronda.codigo}` : ""}
            </h4>
            <Button
              color="success"
              size="sm"
              onClick={() => {
                if (ronda && ronda.informes.length > 0) {
                  generarReporte();
                } else {
                  generarDetallesReporte();
                }
              }}
            >
              Exportar ronda completa
            </Button>
            <Button
              color="success"
              size="sm"
              onClick={() => {
                if (ronda && ronda.informes.length > 0) {
                  generarReporteHallazgos();
                } else {
                  generarDetallesReporteHallazgos();
                }
              }}
            >
              Exportar hallazgos
            </Button>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: "Nombre",
                  accessor: "nombre",
                },
                {
                  Header: "Zona",
                  accessor: "zona",
                },

                {
                  Header: "Actions",
                  accessor: "actions",
                },
              ]}
              data={tbEquipos}
            />
          </CardBody>
        </Card>
      </GridItem>
      {/* Enviando Informe */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={savingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSavingModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>Generando informe, espere un momento...</p>
        </DialogContent>
      </Dialog>
    </GridContainer>
  );
}
